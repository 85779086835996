import Avokado from '@avokadoapp/avokado-ts'
import { AuthDependencies } from 'features/auth/dependencies'
import { interfaces } from 'inversify'
import Constants from 'utils/constant/Constants'

interface _AvokadoClient {
    Public: interfaces.ServiceIdentifier<Avokado>
    Private: interfaces.ServiceIdentifier<Avokado>
}

const AvokadoClient: _AvokadoClient = {
    Public: Symbol.for('AvokadoPublic'),
    Private: Symbol.for('AvokadoPrivate'),
}

export default AvokadoClient

export enum AvokadoClientUsage {
    Public = 'Public',
    Private = 'Private',
}

export function avokadoClientFactory(
    context: interfaces.Context,
    intendedUsage: AvokadoClientUsage
): Avokado {
    const auth = context.container.get(AuthDependencies.Store)

    return new Avokado({
        endpoint: {
            type: 'host',
            host: Constants.Host,
        },
        enableBrowserOptimizedConnection:
            intendedUsage === AvokadoClientUsage.Private,
        tokenProvider: async (): Promise<string> => {
            switch (intendedUsage) {
                case AvokadoClientUsage.Public:
                    return ''
                case AvokadoClientUsage.Private:
                    return `Bearer ${await auth.assertAccessToken()}`
            }
        },
        logging: 'none'
    })
}
