import { LocationOrganizationRetrieve } from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'

export function mapPolicyToModel(
    payload?: LocationOrganizationRetrieve.Response.Policy
): Location.Policy {
    if (!payload) return {}

    const delivery: Location.Policy.Delivery | undefined =
        payload.delivery === undefined
            ? undefined
            : {
                freeDeliveryThreshold: payload.delivery.freeDeliveryThreshold,
                minimumRequiredAmount: payload.delivery.minimumRequiredAmount,
                upfrontPaymentThreshold:
                    payload.delivery.upfrontPaymentThreshold,
                destinations: {
                    postalCodes: payload.delivery.destinations?.postalCodes?.map((elem) => {
                        return {
                            postalCode: elem.postalCode,
                            cost: elem.cost,
                            country: elem.country
                        }
                    }) ?? []
                }
            }

    const reservation: Location.Policy.Reservation | undefined =
        payload.reservation === undefined
            ? undefined
            : {
                allowsRemoteOrder: payload.reservation.allowsRemoteOrder,
                allowsRemoteUpfrontPayment:
                    payload.reservation.allowsRemoteUpfrontPayment,
                automaticConfirmationThreshold:
                    payload.reservation.automaticConfirmationThreshold,
                maximumNumberOfConcurrentReservations:
                    payload.reservation.maximumNumberOfConcurrentReservations,
                maximumNumberOfPeoplePerReservation:
                    payload.reservation.maximumNumberOfPeoplePerReservation,
                reservationDefaultDuration:
                    payload.reservation.reservationDefaultDuration,
                tableHoldingDepositAmount:
                    payload.reservation.tableHoldingDepositAmount,
            }

    const table: Location.Policy.Table | undefined =
        payload.table === undefined
            ? undefined
            : {
                allowsInAppOrder: payload.table.allowsInAppOrder,
                requiresUpfrontPayment: payload.table.requiresUpfrontPayment,
                supportsCourseOrdering: payload.table.supportsCourseOrdering,
                supportsInAppPayment: payload.table.supportsInAppPayment,
                supportsAutomaticConfirmation:
                    payload.table.supportsAutomaticConfirmation,
                coverCost: payload.table.coverCost,
                minimumInAppPaymentAmount:
                    payload.table.minimumInAppPaymentAmount,
            }

    const takeout: Location.Policy.Takeout | undefined =
        payload.takeout === undefined
            ? undefined
            : {
                percentageDiscount: undefined,
                upfrontPaymentThreshold:
                    payload.takeout.upfrontPaymentThreshold,
            }

    return { delivery, reservation, table, takeout }
}
