import {
    Button,
    Collapse,
    Divider,
    Fade,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    VStack,
    useBoolean,
} from '@chakra-ui/react'
import { UseDeliveryPolicyFormDataHook } from 'features/location/hooks/policy/useDeliveryPolicyForm'
import { useTranslation } from 'react-i18next'
import { FiX } from 'react-icons/fi'
import { ListActions } from 'react-use/lib/useList'
import { PriceInput } from 'uikit/form/input'
import { Location } from 'features/location/models/Location'
import { useCallback, useState } from 'react'
import { CountrySelector } from 'features/address/components/CountrySelector'

interface DeliveryFormProps {
    formData: UseDeliveryPolicyFormDataHook
}

// interface PolicyDelivery {
//     deliveryCosts: {
//         [key: string]: number;
//     };
// }

export function DeliveryForm({ formData }: DeliveryFormProps) {
    const { t } = useTranslation()
    const [isShowingDestinations, { on: showDestinations }] = useBoolean(formData.postalCodesDestinations.length <= 5)

    return (
        <VStack align="stretch" pt={8} spacing={12}>
            <PriceInput
                title={t('Free Delivery')}
                description={t(
                    'Threshold above which customers must not pay delivery fees.'
                )}
                value={formData.freeDeliveryThreshold}
                setValue={formData.setFreeDeliveryThreshold}
            />
            <PriceInput
                title={t('Minimum Amount')}
                description={t(
                    'Threshold below which delivery orders are not accepted.'
                )}
                value={formData.minimumRequiredAmount}
                setValue={formData.setMinimumRequiredAmount}
            />
            <PriceInput
                title={t('Upfront Payment Threshold')}
                description={t(
                    'Amount beyond which customers must pay in advance to confirm their order.'
                )}
                value={formData.upfrontPaymentThreshold}
                setValue={formData.setUpfrontPaymentThreshold}
            />

            <Divider my={4} borderWidth={1.5} />

            <Collapse in={isShowingDestinations}>
                <DeliveryCostsInputs formData={formData} />
            </Collapse>
            <Fade in={!isShowingDestinations}>
                <Button onClick={showDestinations}>Destinations</Button>
            </Fade>
            {/* <DeliveryCostsInputs
                deliveryCosts={formData.deliveryCosts}
                handler={formData.setDeliveryCosts}
            /> */}
        </VStack>
    )
}

interface DeliveryCostsInputsProps {
    formData: UseDeliveryPolicyFormDataHook
}

function DeliveryCostsInputs({
    formData
}: DeliveryCostsInputsProps) {
    const { t } = useTranslation()

    return (
        <VStack gap={4}>
            {formData.postalCodesDestinations.map((destination, index) => (
                <HStack w="full" key={index} gap={2}>
                    <IconButton
                        size="xs"
                        aria-label="Remove"
                        colorScheme="gray"
                        borderRadius="full"
                        variant="ghost"
                        icon={<FiX />}
                        onClick={() => formData.removeDestination(index)}
                    />

                    <InputGroup>
                        <InputLeftAddon children="CAP" />
                        <Input
                            value={destination.postalCode}
                            onChange={(e) =>
                                formData.updateDestination(index, {
                                    postalCode: e.target.value,
                                    cost: destination.cost,
                                    country: destination.country
                                })
                            }
                        />
                    </InputGroup>
                    <CountrySelector
                        // @ts-ignore
                        country={destination.country.toUpperCase()}
                        onSelect={(e) => {
                            formData.updateDestination(index, {
                                postalCode: destination.postalCode,
                                cost: destination.cost,
                                country: e
                            })
                        }}
                    />
                    <PriceInput
                        isRequired
                        value={destination.cost}
                        setValue={(value) => {
                            useCallback(() => {
                                formData.updateDestination(index, {
                                    ...destination,
                                    cost: value
                                })
                            }, [destination, formData])
                        }}
                    />
                </HStack>
            ))}

            <Button alignSelf="end"
                variant='ghost'
                onClick={() => formData.addDestination({
                    cost: 0,
                    postalCode: '',
                    country: ''
                })}
            >
                {t('Add destination')}
            </Button>
        </VStack>
    )
}
