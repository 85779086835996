import { MenuAssignmentCreate } from '@avokadoapp/avokado-ts'
import { Schedule } from 'features/calendar/models/Calendar'
import { MenuAssignment } from '../models/MenuAssignment'

type Day = keyof Schedule

export function mapModelToAvailabilityTimes(
    availabilities: MenuAssignment.Availabilities
): MenuAssignmentCreate.Request.AvailabilityTime[] | undefined {
    if (availabilities === 'always') {
        return undefined
    }

    const times = []
    for (const day in availabilities) {
        if (!availabilities.hasOwnProperty(day)) {
            continue
        }

        const schedule = availabilities[day as Day]
        if (schedule.type === 'full-day' && schedule.isActive) {
            times.push({
                weekDay: day as Day,
                from: 0,
                to: 0,
            })
        } else if (schedule.type === 'time-range') {
            times.push(
                ...schedule.timeRanges.map((range) => ({
                    weekDay: day as Day,
                    from: range.from.hours * 60 + range.from.minutes,
                    to: range.to.hours * 60 + range.to.minutes,
                }))
            )
        }
    }

    return times
}

export function mapModelToRestrictions(
    restrictions: MenuAssignment.Restriction[]
): MenuAssignmentCreate.Request.Restriction[] {
    return restrictions.map((restriction) => {
        switch (restriction) {
            case 'table':
                return 'table'
            case 'delivery':
                return 'delivery'
            case 'take-away':
                return 'takeout'
        }
    })
}
