import { LocationDependencies } from 'features/location/dependencies'
import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import { useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import {
    UseDeliveryPolicyFormDataHook,
    useDeliveryPolicyFormData,
} from './useDeliveryPolicyForm'
import {
    UseReservationPolicyFormDataHook,
    useReservationPolicyFormData,
} from './useReservationPolicyForm'
import {
    UseTablePolicyFormDataHook,
    useTablePolicyFormData,
} from './useTablePolicyForm'
import {
    UseTakeoutPolicyFormDataHook,
    useTakeoutPolicyFormData,
} from './useTakeoutPolicyForm'

interface UsePolicyFormDataHook {
    delivery: UseDeliveryPolicyFormDataHook
    reservation: UseReservationPolicyFormDataHook
    table: UseTablePolicyFormDataHook
    takeout: UseTakeoutPolicyFormDataHook
}

export function usePolicyFormData(
    policy: Location.Policy
): UsePolicyFormDataHook {
    const delivery = useDeliveryPolicyFormData(policy.delivery)
    const reservation = useReservationPolicyFormData(policy.reservation)
    const table = useTablePolicyFormData(policy.table)
    const takeout = useTakeoutPolicyFormData(policy.takeout)

    return { delivery, reservation, table, takeout }
}

export function usePolicyFormController(
    location: Location,
    params: UsePolicyFormDataHook,
    onSave: (location: Location) => void
): UseFormControllerHook<void> {
    const update = useInjection(LocationDependencies.Update)

    const [isProcessing, setIsProcessing] = useState(false)
    const taskFeedback = useTaskFeedback()

    const canSubmit = useMemo(() => {
        const hasChanges =
            params.delivery.hasChanges ||
            params.reservation.hasChanges ||
            params.table.hasChanges ||
            params.takeout.hasChanges

        return hasChanges && params.delivery.canSubmit
    }, [params])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const newLocation = await update.run({
                location: location,
                policy: {
                    delivery: params.delivery.policy,
                    reservation: params.reservation.policy,
                    table: params.table.policy,
                    takeout: params.takeout.policy,
                },
            })
            onSave(newLocation)
            taskFeedback.succeed()
        } catch (e: unknown) {
            taskFeedback.fail()
        } finally {
            setIsProcessing(false)
        }
    }

    return {
        isProcessing,
        canSubmit,
        submit,
        isSuccess: taskFeedback.isSuccess,
        failure: taskFeedback.failure,
    }
}
