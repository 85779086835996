import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

export interface UseTakeoutPolicyFormDataHook {
    isEnabled: boolean
    hasChanges: boolean
    policy: Location.Policy.Takeout | undefined
    percentageDiscount: number | undefined
    upfrontPaymentThreshold: number | undefined
    setIsEnabled: Dispatch<SetStateAction<boolean>>
    setPercentageDiscount: Dispatch<SetStateAction<number | undefined>>
    setUpfrontPaymentThreshold: Dispatch<SetStateAction<number | undefined>>
}

export function useTakeoutPolicyFormData(
    takeout?: Location.Policy.Takeout
): UseTakeoutPolicyFormDataHook {
    const [isEnabled, setIsEnabled] = useState(takeout !== undefined)
    const [percentageDiscount, setPercentageDiscount] = useState(
        takeout?.percentageDiscount
    )
    const [upfrontPaymentThreshold, setUpfrontPaymentThreshold] = useState(
        takeout?.upfrontPaymentThreshold
    )

    const policy: Location.Policy.Takeout | undefined = useMemo(() => {
        return isEnabled
            ? {
                percentageDiscount,
                upfrontPaymentThreshold,
            }
            : undefined
    }, [isEnabled, percentageDiscount, upfrontPaymentThreshold])

    const hasChanges = useMemo(
        () => !_.isEqual(policy, takeout),
        [policy, takeout]
    )

    return {
        isEnabled,
        hasChanges,
        policy,
        percentageDiscount,
        upfrontPaymentThreshold,
        setIsEnabled,
        setPercentageDiscount,
        setUpfrontPaymentThreshold,
    }
}
