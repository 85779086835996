import { MenuAssignmentList } from '@avokadoapp/avokado-ts'
import { extractSchedule } from 'features/calendar/mapper/InternalMapper'
import { MenuAssignment } from '../models/MenuAssignment'

export function mapMenuAssignmentsToModels(
    payload: MenuAssignmentList.Response
): MenuAssignment[] {
    return payload.menuAssignments.map(mapMenuAssignmentToModel)
}

export function mapMenuAssignmentToModel(
    payload: MenuAssignmentList.Response.MenuAssignment
): MenuAssignment {
    return {
        id: {
            organizationId: payload.organizationId,
            locationId: payload.locationId,
            menuId: payload.menuId,
            menuAssignmentId: payload.menuAssignmentId,
        },
        isActive: payload.isActive,
        incompatibleMenusIds: payload.incompatibleMenusIds,
        availabilities: payload.availabilities
            ? extractSchedule(payload.availabilities)
            : 'always',
        restrictions: mapRestrictionsToModel(payload.restrictions),
    }
}

function mapRestrictionsToModel(
    payload: MenuAssignmentList.Response.Restriction[]
): MenuAssignment.Restriction[] {
    return payload.map((restriction) => {
        switch (restriction) {
            case 'table':
                return 'table'
            case 'delivery':
                return 'delivery'
            case 'takeout':
                return 'take-away'
        }
    })
}
