import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useList } from 'react-use'

export interface UseDeliveryPolicyFormDataHook {
    isEnabled: boolean
    hasChanges: boolean
    canSubmit: boolean
    policy: Location.Policy.Delivery | undefined
    freeDeliveryThreshold: number | undefined
    minimumRequiredAmount: number | undefined
    upfrontPaymentThreshold: number | undefined
    postalCodesDestinations: Location.Policy.Delivery.PostalCodeDestination[]
    setIsEnabled: Dispatch<SetStateAction<boolean>>
    setFreeDeliveryThreshold: Dispatch<SetStateAction<number | undefined>>
    setMinimumRequiredAmount: Dispatch<SetStateAction<number | undefined>>
    setUpfrontPaymentThreshold: Dispatch<SetStateAction<number | undefined>>
    addDestination: (...i: Location.Policy.Delivery.PostalCodeDestination[]) => void
    removeDestination: (i: number) => void
    updateDestination: (i: number, l: Location.Policy.Delivery.PostalCodeDestination) => void
}

export function useDeliveryPolicyFormData(
    delivery?: Location.Policy.Delivery
): UseDeliveryPolicyFormDataHook {
    const [isEnabled, setIsEnabled] = useState(delivery !== undefined)

    const [freeDeliveryThreshold, setFreeDeliveryThreshold] = useState(
        delivery?.freeDeliveryThreshold
    )
    const [minimumRequiredAmount, setMinimumRequiredAmount] = useState(
        delivery?.minimumRequiredAmount
    )
    const [upfrontPaymentThreshold, setUpfrontPaymentThreshold] = useState(
        delivery?.upfrontPaymentThreshold
    )

    const [postalCodesDestinations, { push, removeAt, updateAt }] = useList(delivery?.destinations?.postalCodes ?? [])

    const policy: Location.Policy.Delivery | undefined = useMemo(() => {
        return isEnabled
            ? {
                freeDeliveryThreshold,
                minimumRequiredAmount,
                upfrontPaymentThreshold,
                destinations: {
                    postalCodes: postalCodesDestinations
                }
            }
            : undefined
    }, [
        isEnabled,
        freeDeliveryThreshold,
        minimumRequiredAmount,
        upfrontPaymentThreshold,
        postalCodesDestinations
    ])

    const hasChanges = useMemo(
        () => !_.isEqual(policy, delivery),
        [policy, delivery]
    )

    // TODO: use new destination APIs
    const canSubmit = true

    return {
        isEnabled,
        hasChanges,
        canSubmit,
        policy,

        freeDeliveryThreshold,
        minimumRequiredAmount,
        upfrontPaymentThreshold,
        postalCodesDestinations,
        setIsEnabled,

        setFreeDeliveryThreshold,
        setMinimumRequiredAmount,
        setUpfrontPaymentThreshold,
        addDestination: push,
        removeDestination: removeAt,
        updateDestination: updateAt
    }
}
